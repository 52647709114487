import React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ArrowLeft, Mail, LinkedinIcon, TwitterIcon, Check } from 'lucide-react';
import './App.css';
import { images } from './images';
import WebAutomation from './img/web_automation.png';
import TaskAutomation from './img/task_automation.png';
import Agent from './img/agent.png';
import LlmApp from './img/llm_app.png';
import AiSalesRep from './img/ai_sales_rep.png';
import MedicalExtractionGrid from './img/medical_extraction_grid.png';
import LinkedInRecruitmentBrowserAutomation from './img/linkedin_recruitment_browser_automation.png';

function Home() {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center min-h-screen bg-black text-white p-4">
      <div className="flex flex-col items-center space-y-12">
        <div className="text-gray-500 flex space-x-4">
          <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/about')}>About</div>
          <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/services')}>Services</div>
          <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/portfolio')}>Portfolio</div>
          <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/contact')}>Contact</div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <div className="text-6xl font-bold">AgentScale AI</div>
          <div>Automation with RPA, LLM and Agents.</div>
        </div>
        <div className="text-gray-500 text-center max-w-xl"><span className="hover:text-white transition-colors duration-300">AgentScale AI</span> empowers businesses with <span className="font-semibold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 to-orange-500">cutting-edge automation solutions</span> using robotic process automation, large language models and AI agents.</div>
        <div className="grid grid-cols-7 gap-1">
          {images.map((image, index) => (
            <img key={index} src={image} alt="" className="w-10 h-10 bg-white rounded-full p-1" />
          ))}
        </div>
      </div>
    </div >
  )
}

function Header() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between p-8">
      <div className="cursor-pointer" onClick={() => navigate('/')}><ArrowLeft /></div>
      <div className="text-gray-500 flex space-x-8">
        <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/about')}>About</div>
        <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/services')}>Services</div>
        <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/portfolio')}>Portfolio</div>
        <div className="cursor-pointer hover:text-white transition-colors duration-300" onClick={() => navigate('/contact')}>Contact</div>
      </div>
    </div>
  )
}

function About() {
  return (
    <div className="bg-black text-white min-h-screen">
      <Header />
      <div className="p-8 space-y-4">
        <div className="text-3xl font-bold">About</div>
        <div className="max-w-xl text-gray-400">We are a team of engineers specialized in AI automation based in Singapore. We are dedicated to building automation solutions to real business problems.</div>
      </div>
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-2">
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div>@jetnew_sg</div>
          <div className="text-3xl font-bold">Jet New</div>
          <div className="font-semibold">Director / AI Engineer</div>
          <div className="text-sm">Jet has deployed end-to-end automation solutions for startups, SMBs and VC firms across EdTech, Consultancy and Finance. He was previously data scientist at Indeed, AI researcher at NUS and machine learning engineer at Grab and IMDA.</div>
          <div className="text-sm">He has led multiple award-winning AI projects at international and local competitions and hackathons, including 4Catalyzer LLM4Bio Hackathon, HackAsia Global Hackathon, Optigram Data Visualization Competition and NTU iNTUition Hackathon.</div>
          <div className="text-sm">Jet graduated with a B.Comp. in Computer Science, top student in machine learning, from the National University of Singapore, enrolled in the Turing Research Programme and University Scholars Programme.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div>Join us!</div>
          <div className="text-3xl font-bold">Maybe you? </div>
          <div className="text-sm">We are hiring across AI engineer and software engineer roles with at least 3 years of experience.</div>
          <div className="flex flex-wrap">
            <span className="text-sm bg-gray-700 text-white px-3 py-1 rounded-md mr-2 mb-2">Software Engineer</span>
            <span className="text-sm bg-gray-700 text-white px-3 py-1 rounded-md mr-2 mb-2">AI Engineer</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function Services() {
  return (
    <div className="bg-black text-white min-h-screen">
      <Header />
      <div className="p-8 space-y-4">
        <div className="text-3xl font-bold">Services</div>
        <div className="max-w-xl text-gray-400">We specialize in delivering state-of-the-art AI automation solutions specially tailored to meet the unique needs of your business.</div>
      </div>
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-3xl font-bold">LLM Application Development</div>
          <img src={LlmApp} alt="LLM Application" className="w-full h-auto" />
          <div>Create a large language model (LLM) application - demo, POC, MVP or product - for your specific business use case.</div>
          <div>We adopt industry-frontier LLM best practices, ensuring high quality, fast and cost-efficient applications for your use case.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-3xl font-bold">Web / Browser Automation</div>
          <img src={WebAutomation} alt="Web Automation" className="w-full h-auto rounded-xl border border-gray-500" />
          <div>Automate web scraping and other browser tasks with the flexibility and decision making of large language models.</div>
          <div>We employ our proprietary suite of LLM tools optimized for web content and web automation, guaranteeing robust and reliable workflows.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-3xl font-bold">Task / Process Automation</div>
          <img src={TaskAutomation} alt="Task Automation" className="w-full h-auto rounded-xl border border-gray-500" />
          <div>Automate business tasks and processes with the customizability and intelligence of large language models.</div>
          <div>We deploy our extensive suite of external integrations - applications, APIs and websites - to tailor to your unique needs.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-3xl font-bold">AI Agent Development</div>
          <img src={Agent} alt="Agent" className="w-full h-auto rounded-xl border border-gray-500" />
          <div>Develop an autonomous AI agent to completely delegate away a business function.</div>
          <div>We specialize in state-of-the-art AI agent research, designing and developing previously-impossible open-ended automation solutions.</div>
        </div>
      </div>
    </div >
  )
}

function Portfolio() {
  return (
    <div className="bg-black text-white min-h-screen">
      <Header />
      <div className="p-8 space-y-4">
        <div className="text-3xl font-bold">Portfolio</div>
        <div className="max-w-xl text-gray-400">We've worked closely with startups, SMBs and boutique VC firms.</div>
      </div>
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-2 lg:grid-cols-3">
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-sm">AI Agent Development</div>
          <div className="text-3xl font-bold">AI Sales Rep</div>
          <img src={AiSalesRep} alt="AI Sales Rep" className="w-full h-auto rounded-md" />
          <div>Autonomous AI agent to research and qualify B2B leads to ICP and hyper-personalize cold emails with industry best practices.</div>
          <div className="grid grid-cols-2">
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">10x reply rates</span>
            </div>
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">90% cost savings</span>
            </div>
          </div>
          <div>Our <span className="text-cyan-400 font-bold">robust scripts optimized for web research</span> can be applied to your web scraping needs.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-sm">LLM Application Development</div>
          <div className="text-3xl font-bold">Medical Extraction Grid</div>
          <img src={MedicalExtractionGrid} alt="Medical Extraction Grid" className="w-full h-auto rounded-md" />
          <div>LLM-based scraper to extract structured data fields from medical research papers for reliable systematic literature reviews at scale.</div>
          <div className="grid grid-cols-2">
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">100x literature coverage</span>
            </div>
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">40% hours saved</span>
            </div>
          </div>
          <div>Our <span className="text-fuchsia-400 font-bold">precise prompt tools designed for medical data</span> can be applied to your data extraction needs.</div>
        </div>
        <div className="border border-gray-500 rounded-2xl p-8 space-y-4">
          <div className="text-sm">Web / Browser Automation</div>
          <div className="text-3xl font-bold">LinkedIn Recruitment Browser Automation</div>
          <img src={LinkedInRecruitmentBrowserAutomation} alt="LinkedIn Recruitment Browser Automation" className="w-full h-auto rounded-md" />
          <div>LinkedIn Sales Navigator automation using Chrome extension to automatically send connection invites to top job candidates.</div>
          <div className="grid grid-cols-2">
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">50x job applies</span>
            </div>
            <div className="flex">
              <Check className="text-green-500 mr-2" /><span className="text-sm">80% cost savings</span>
            </div>
          </div>
          <div>Our <span className="text-amber-400 font-bold">extensive expertise in web and browser automation</span> guarantees your reliability needs.</div>
        </div>
      </div>
    </div >
  )
}

function Contact() {
  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <Header />
      <div className="p-8 space-y-4">
        <div className="text-3xl font-bold">Get in Touch</div>
        <div className="max-w-xl text-gray-400">Let's chat to learn how we can best meet your needs.</div>
      </div>
      <div className="grid grid-cols-1 gap-8 p-8 w-full text-center md:grid-cols-3 md:h-96">
        <a href="mailto:jet@agentscale.ai?subject=Re:%20" target="_blank" rel="noopener noreferrer" className="border border-gray-500 rounded-2xl p-8 space-y-4 flex flex-col items-center justify-center">
          <div className="border border-gray-500 p-3 rounded-full"><Mail className="w-5 h-5" /></div>
          <div>Drop us an email</div>
        </a>
        <a href="https://linkedin.com/in/jetnew" target="_blank" rel="noopener noreferrer" className="border border-gray-500 rounded-2xl p-8 space-y-8 flex flex-col items-center justify-center">
          <div className="border border-gray-500 p-3 rounded-full"><LinkedinIcon className="w-5 h-5" /></div>
          <div>Connect on LinkedIn</div>
        </a>
        <a href="https://x.com/jetnew_sg" target="_blank" rel="noopener noreferrer" className="border border-gray-500 rounded-2xl p-8 space-y-4 flex flex-col items-center justify-center">
          <div className="border border-gray-500 p-3 rounded-full"><TwitterIcon className="w-5 h-5" /></div>
          <div>Follow on Twitter</div>
        </a>
      </div>
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
