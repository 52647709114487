import gmail from './img/gmail.webp';
import reddit from './img/reddit.webp';
import openai from './img/openai.webp';
import gdrive from './img/gdrive.webp';
import gdocs from './img/gdocs.webp';
import gsheets from './img/gsheets.webp';
// import hubspot from './img/hubspot.webp';
import youtube from './img/youtube.webp';
import hacker_news from './img/hacker_news.webp';
import google from './img/google.webp';
import twitter from './img/twitter.webp';
import gcalendar from './img/gcalendar.webp';
import slack from './img/slack.webp';
import notion from './img/notion.webp';
import airtable from './img/airtable.webp';

export const images = [
    gmail,
    reddit,
    openai,
    gdrive,
    gdocs,
    gsheets,
    // hubspot,
    youtube,
    hacker_news,
    google,
    twitter,
    gcalendar,
    slack,
    notion,
    airtable,
];
